import React, { FC, memo, useMemo, useRef, useState } from 'react';

import type {
  ApiAvailableReaction,
  ApiChatReactions,
  ApiReaction,
  ApiReactionCount,
} from '../../../api/types';

import { getTouchY } from '../../../util/scrollLock';
import { createClassNameBuilder } from '../../../util/buildClassName';

import {
  isSameReaction,
  canSendReaction,
  getReactionUniqueKey,
  sortReactions,
} from '../../../global/helpers';

import ReactionSelectorReaction from './ReactionSelectorReaction';

import './ReactionSelector.scss';
import classNames from 'classnames';
import Button from '../../ui/Button';
import IconSvg from '../../ui/IconSvg';
import useLastCallback from '../../../hooks/useLastCallback';

type OwnProps = {
  availableReactions?: ApiAvailableReaction[];
  allAvailableReactions?: ApiAvailableReaction[];
  enabledReactions?: ApiChatReactions;
  topReactions?: ApiReaction[];
  onToggleReaction: (reaction: ApiReaction) => void;
  isPrivate?: boolean;
  currentReactions?: ApiReactionCount[];
  maxUniqueReactions?: number;
  isReady?: boolean;
  canBuyPremium?: boolean;
  isCurrentUserPremium?: boolean;
  className?: string;
};

const REACTIONS_AMOUNT = 7;

const ReactionSelector: FC<OwnProps> = ({
  topReactions,
  enabledReactions,
  currentReactions,
  maxUniqueReactions,
  allAvailableReactions,
  isPrivate,
  isReady,
  onToggleReaction,
  className,
}) => {
  // eslint-disable-next-line no-null/no-null
  const ref = useRef<HTMLDivElement>(null);

  const [showAll, setShowAll] = useState(false);

  const handleWheel = (e: React.WheelEvent | React.TouchEvent) => {
    const deltaY = 'deltaY' in e ? e.deltaY : getTouchY(e);

    if (deltaY && e.cancelable) {
      e.preventDefault();
    }
  };

  const availableReactions = useMemo(() => {
    const reactions =
      allAvailableReactions
        ?.map((availableReaction) => {
          if (availableReaction.isInactive) return undefined;
          if (
            !isPrivate &&
            (!enabledReactions ||
              !canSendReaction(availableReaction.reaction, enabledReactions))
          ) {
            return undefined;
          }
          if (
            maxUniqueReactions &&
            currentReactions &&
            currentReactions.length >= maxUniqueReactions &&
            !currentReactions.some(({ reaction }) =>
              isSameReaction(reaction, availableReaction.reaction)
            )
          ) {
            return undefined;
          }
          return availableReaction;
        })
        .filter(Boolean) || [];

    return sortReactions(reactions, topReactions);
  }, [
    allAvailableReactions,
    currentReactions,
    enabledReactions,
    isPrivate,
    maxUniqueReactions,
    topReactions,
  ]);

  const reactionsToRender = useMemo(() => {
    return showAll
      ? availableReactions
      : availableReactions.slice(0, REACTIONS_AMOUNT);
  }, [availableReactions, showAll]);

  const withMoreButton = availableReactions.length > REACTIONS_AMOUNT;

  const userReactionIndexes = useMemo(() => {
    const chosenReactions =
      currentReactions?.filter(
        ({ chosenOrder }) => chosenOrder !== undefined
      ) || [];
    return new Set(
      chosenReactions.map(({ reaction }) =>
        reactionsToRender.findIndex(
          (r) => r && isSameReaction(r.reaction, reaction)
        )
      )
    );
  }, [currentReactions, reactionsToRender]);

  const handleShowMoreClick = useLastCallback(() => {
    setShowAll(!showAll);
  });

  if (!reactionsToRender.length) return null;

  return (
    <div
      className={classNames(className, 'ReactionSelector', {
        'with-button': withMoreButton,
      })}
      onWheelCapture={handleWheel}
      onTouchMove={handleWheel}
      ref={ref}
    >
      <div className='ReactionSelector__items-wrapper'>
        <div className='ReactionSelector__items no-scrollbar'>
          {reactionsToRender.map((reaction, i) => {
            if (!reaction) return undefined;
            return (
              <ReactionSelectorReaction
                key={getReactionUniqueKey(reaction.reaction)}
                previewIndex={i}
                isReady={isReady}
                onToggleReaction={onToggleReaction}
                reaction={reaction}
                chosen={userReactionIndexes.has(i)}
              />
            );
          })}
          {withMoreButton && (
            <Button
              color='translucent'
              className={classNames('ReactionSelector__show-more', {
                show: showAll,
              })}
              onClick={handleShowMoreClick}
            >
              <IconSvg name='arrow-down' />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ReactionSelector);
